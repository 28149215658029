.pie-small {
    position: relative;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    background: yellowgreen;
    background-image:
        linear-gradient(to right, transparent 50%, #655 0);
    color: transparent;
    text-align: center;
}

@keyframes spin {
    to {
        transform: rotate(.5turn);
    }
}

@keyframes bg {
    50% {
        background: #655;
    }
}

.pie-small::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background-color: inherit;
    transform-origin: left;
    animation: spin 50s linear infinite,
        bg 100s step-end infinite;
    animation-play-state: paused;
    animation-delay: inherit;
}